import { coreApi } from "../../app/core-api";
import { StockAnnouncement, StockAnnouncementsFilter } from "./stock-market.types";

const endpointPrefix = "stockmarket";

export const stockMarketApi = {
    getAnnouncements,
};

function getAnnouncements(filter: StockAnnouncementsFilter) {
    return coreApi.get<StockAnnouncement[]>(`${endpointPrefix}/announcements`, filter);
}
