import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dateHelpers } from "../../app/helpers";
import { StockAnnouncementsFilter, StockMarketState } from "./stock-market.types";
import { stockMarketApi } from "./stock-market.api";
import { RootState } from "../../app/store";

const initialState: StockMarketState = {
    announcements: [],
    filter: {
        backDaysCount: 1,
        from: dateHelpers.toIsoString(new Date()),
        to: dateHelpers.toIsoString(new Date()),
        announcementCategories: "CASH DIVIDEND,RIGHTS ISSUE",
    },
};

export const fetchStockMarketAnnouncementsAsync = createAsyncThunk(
    "stockMarket/fetchAnnouncements",
    async (filter: StockAnnouncementsFilter) => {
        return stockMarketApi.getAnnouncements(filter);
    }
);

export const stockMarketSlice = createSlice({
    name: "stockMarket",
    initialState,
    reducers: {
        setAnnouncements(state, action) {
            state.announcements = action.payload;
        },
        setAnnouncementsFilter(state, action: PayloadAction<any>) {
            state.filter = { ...state.filter, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStockMarketAnnouncementsAsync.fulfilled, (state, action) => {
                state.announcements = action.payload;
                state.announcementsLoading = false;
            })
            .addCase(fetchStockMarketAnnouncementsAsync.pending, (state) => {
                state.announcementsLoading = true;
            })
            .addCase(fetchStockMarketAnnouncementsAsync.rejected, (state) => {
                state.announcementsLoading = false;
            });
    },
});

export const { setAnnouncements, setAnnouncementsFilter } = stockMarketSlice.actions;

export default stockMarketSlice.reducer;

export const stockMarketAnnouncementsFilterSelector = (state: RootState) => state.stockMarket.filter;
export const stockMarketAnnouncementsSelector = (state: RootState) => state.stockMarket.announcements;
export const stockMarketAnnouncementsLoadingSelector = (state: RootState) => state.stockMarket.announcementsLoading;
