import { Dictionary } from "./types";

export const SapPermissions = {
    lookupsFullAccess: "LOOKUPS_FULL_MANAGE",
    projectsFullAccess: "PROJECTS_FULL_ACCESS",
    adminControl: "ADMIN_CONTROL",
    usersFullAccess: "USERS_FULL_ACCESS",
    attendanceDevicesFullAccess: "ATTENDANCE_DEVICES_FULL_ACCESS",
    manageTenants: "MANAGE_TENANTS",

    //Finance
    transactionEntry: "TRANSACTION_ENTRY",
    transactionReconcile: "TRANSACTION_RECONCILE",
    transactionView: "TRANSACTION_VIEW",

    //Farming
    cropTrackingFullAccess: "CROP_TRACKING_FULL_ACCESS",
    harvestLogFullAccess: "HARVEST_LOG_FULL_ACCESS",

    //HR
    workLogsView: "WORK_LOGS_VIEW",
    workLogsSelfView: "WORK_LOGS_SELF_VIEW",
    workLogsFullAccess: "WORK_LOGS_FULL_ACCESS",
    payrollFullAccess: "PAYROLL_FULL_ACCESS",
    attendanceLogsView: "ATTENDANCE_LOGS_VIEW",
    attendanceLogsFullAccess: "ATTENDANCE_LOGS_FULL_ACCESS",
    attendanceDevicesView: "ATTENDANCE_DEVICES_VIEW",
    employeesFullAccess: "EMPLOYEES_FULL_ACCESS",
    employeesView: "EMPLOYEES_VIEW",
    leaveTypesFullAccess: "LEAVE_TYPES_FULL_ACCESS",
    leaveManage: "LEAVE_MANAGE",
    leaveApprove: "LEAVE_APPROVE",
    leaveFinalApprove: "LEAVE_FINAL_APPROVE",
    leaveRequest: "LEAVE_REQUEST",
    leaveRequestOnBehalfOf: "LEAVE_REQUEST_ONBEHALF",

    //Stock Market
    stockMarketEventAnalytics: "STOCK_MARKET_EVENT_ANALYTICS",
};

export const ErrorCodes: Dictionary = {
    ERR_CANT_DELETE_RECONCILED_TXN: "Reconciled transactions cannot be deleted.",
    ERR_INSUFFICIENT_PERMISSION_TO_DELETE_TRNASACTION: "You don't have permission to delete this transaction.",
};

export const Features = {
    Finance: {
        Transactions: "TRANSACTIONS",
        ShareDividend: "SHARE_DIVIDEND",
        BillingProofUpload: "BILLING_PROOF_UPLOAD",
    },
    Farming: {
        HarvestLog: "HARVEST_LOG",
        CropTracking: "CROP_TRACKING",
        AdvancedCropTracking: "ADVANCED_CROP_TRACKING",
    },
    HR: {
        EmployeeManagement: "EMPLOYEE_MANAGEMENT",
        AttendanceDevices: "ATTENDANCE_DEVICES",
        WorkLogs: "WORK_LOGS",
        Payroll: "PAYROLL",
        LeaveManagement: "LEAVE_MANAGEMENT",
    },
    StockMarket: {
        EventAnalytics: "STOCK_MARKET_EVENT_ANALYTICS",
    },
};

export const LookupHeaders = {
    AllowanceTypes: "ALLOWANCE_TYPES",
};

export const ConfigKeys = {
    Payroll: {
        PayrollDate: "PAYROLL_REPORT_GENERATE_DATE",
        OTPaying: "PAYROLL_OT_PAYING",
    },
    WorkLog: {
        HasCustomWorkingDays: "WORKLOG_HAS_CUSTOM_WORKING_DAYS",
        TimeTracking: "WORKLOG_TIME_TRACKING",
        ProjectSelect: "WORKLOG_PROJECT_SELECT",
        WeeklyWorkingDays: "WORKLOG_WEEKLY_WORKING_DAYS_AND_HOURS",
        HandleLeave: "WORKLOG_HANDLE_LEAVE",
    },
    HR: {
        EmployeeSystemAccess: "HR_EMPLOYEE_SYSTEM_ACCESS",
    },
};

export const NATURE_OF_BUSINESSES = [
    { key: "Farming", value: "Farming" },
    { key: "Retail", value: "Retail" },
    { key: "Other", value: "Other" },
    { key: "Household", value: "Household" },
];

export const EMPLOYMENT_CONTRACT_TYPES = {
    PermanentExecutive: "PERMANENT_EXECUTIVE",
    PermanentNormal: "PERMANENT_NORMAL",
    PermanentProbation: "PERMANENT_PROBATION",
    TemporaryPayg: "TEMPORARY_PAYG",
    CasualDailyWage: "CASUAL_DAILY_WAGE",
};

export const DefaultPageSizes = {
    CropStats: 50,
    Trees: 50,
    HarvestLogs: 25,
    WorkLogs: 50,
    Transactions: 50,
    Employees: 25,
    AttendanceLogs: 50,
    EmployeeLeave: 25,
    LeaveRequests: 25,
};

export const REGEX_PHONE_NUMBER = /^(?:(?:\+?\d{1,3}[ -]?)?(?:\(?\d{1,3}\)?[ -]?)?\d{3}[ -]?\d{3}[ -]?\d{4}|\d{10})$/;

export const LookupTypes = {
    IncomeTypes: "INCOME_TYPES",
    ExpenseTypes: "EXPENSE_TYPES",
    CropFallenReasons: "CROP_FALLEN_REASONS",
};

export const LookupTitles = {
    [LookupTypes.IncomeTypes]: "Income Types",
    [LookupTypes.ExpenseTypes]: "Expense Types",
    [LookupTypes.CropFallenReasons]: "Crop Fallen Reasons",
};
